.main-projects {
  background-image: url(../../../assets/img/launchpad-bg.png);;
}

.dark-bg {
  /*background-image: url(../../../assets/img/launchpad-bg.png);;*/
}
.w-64 {
  width: 16rem !important;
}
.clipped-b:not(#\#):not(#\#) {
  margin-top: -1px;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 100%, 15px 100%);
  clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 100%, 15px 100%);
  background-color: #0b0b0f;
}
.-bottom-5 {
  bottom: -1.25rem !important;
}
.right-0 {
  right: 0 !important;
}

.left-0 {
  left: 0 !important;
}
.absolute {
  position: absolute !important;
}
.firstspan {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}
.secondspan {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}
.thirdImg {
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.o-img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.mt-14 {
  
    margin-top: 3.5rem!important;
}
.text-xl{
    color: rgba(255,255,255,1)!important;
    line-height: 1.75rem!important;
    font-size: 1.25rem!important;
    text-align: center!important;
    font-weight: 600!important;
}
.text-white\/60 {
    color: hsla(0,0%,100%,.6)!important;
}
.max-width{
  width: calc(100% - 250px);
  margin-right: auto !important;
  margin-left: auto !important;
}
@media (max-width:1100px) {
  .max-width{
    width: 100%;
  }
  
}
@media (min-width: 960px) {
  .md\:text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
  .md\:w-64 {
    width: 16rem !important;
  }
  .md\:text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}
@media (min-width: 1024px) {
  .lg\:text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}
@media (min-width: 1280px) {
  .xl\:w-96 {
    width: 27rem !important;
  }
  .xl\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
}
