.component {
  width: 100%;
}

.connectBtn,
.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  background-color: var(--bg-color-2);
  font-size: 16px;
  line-height: 19px;
  border: none;
  border-radius: 90px;
  /* width: 150px; */
}

.overlay {
  width: 320px;
}

.notificationBtn {
  cursor: pointer;
  color: var(--text-color-1);
  margin-top: 8px;
  margin-right: 12px;
}

.notificationHeader {
  padding: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-3);
}

.notificationBody {
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-1);
}

.notificationZero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 80px 0;
}

.notificationZero svg {
  width: 139px;
  height: 128px;
}

.notificationZero span {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-color-2);
}

.walletBox {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.divider {
  width: 1px;
  height: auto;
  margin: 0 24px;
  background-color: #e3e4e6;
}

.walletPreview {
  cursor: pointer;
  padding: 11px 0px;
  background-color: var(--bg-color-2);
  border-radius: 90px;
  /* width: 130px; */
  display: flex;
  /* justify-content: center; */

  background: transparent !important;
  color: #651fff !important;
  border: 2px solid #651fff !important;
  border-radius: 5px;
  padding: 12.8px 32px !important;
}

.walletPreviewAvatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 4px;
}

.walletPreviewHash {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.walletPreviewHashOnBtn {
  font-size: 14px;
  line-height: 19px;
  /* color: var(--text-color-1);
 */
  /* color: black; */

  /* border: 1px solid #651fff; */
   color: #651fff!important;
  /* padding: 7.5px;  */
  border-radius: 7.5px;
  font-weight: bold;
}

.walletPreviewArrow {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  transform: rotate(180deg);
}

.walletHeader {
  padding: 20px 16px 20px 14px;
  align-items: center;
  display: flex;
}

.walletAvatarCol {
  color: #fff;
}

.walletAvatar {
  width: 30px;
  margin-right: 20px;
}

.walletHash {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #4f6ae6;
}

.stats {
  padding-left: 16px;
  padding-right: 16px;
}

.statRow {
  color: #7f7f94;
  display: flex;
}

.statRow :global div:nth-child(2) {
  flex-grow: 1;
}

.statRow + .statRow {
  margin-top: 32px;
}

.statIcon {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  color: #fff;
}

.statName {
  flex-grow: 1;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-right: 10px;
}

.statValue {
  font-size: 16px;
  line-height: 20px;
  color:#651fff!important
}

.statTag {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* color: #ff9600;
   */
   color: #651fff!important;
  font-weight: 300;
}

.disconnectBtnRow {
  padding: 24px;
  justify-content: center;
}

.disconnectBtn,
.disconnectBtn:hover {
  padding: 6px 20px !important;
  border: 1.5px solid #fff !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  color: #fff !important;
  font-weight: 500 !important;
  border-radius: 40px !important;
  background: #0a0a0a !important;
  width: 100%;
}

.hidden {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .walletPreview {
    justify-content: flex-start;
    padding: 11px 0px;
    line-height: 15px;
    font-size: 14px !important;
  }

  .walletPreviewHashOnBtn {
    line-height: 15px;
  }

  .connectBtn,
  .connectBtn:hover,
  .connectBtn:active,
  .connectBtn:focus {
    /* width: 120px; */
    line-height: 15px;
  }
}

.connectbtn {
  /* width: 130px !important;
  height: 30px !important; */
  font-size: 16px;

  background: transparent !important;
  color: #651fff !important;
  border: 2px solid #651fff !important;
  border-radius: 5px;
  padding: 10px 20px;
}

@media (min-width: 991px) {
  .connectbtn {
      /* width: 100px !important;
      height: 50px !important; */
      font-size: 12px;
  }
}

@media (min-width: 1300px) {
  .connectbtn {
      /* width: 200px !important;
      height: 40px !important; */
      font-size: 18px;
  }
}