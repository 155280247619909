.syncera-shield {
  font-size: 27.5px;
  transform: translate(6.5px, 6.5px);
}

.text-paragraph-text {
  --tw-text-opacity: 1;
  color: white;
}
.leading-\[26px\] {
  line-height: 26px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.mb-30px {
  margin-bottom: 30px;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.def-height{
  display: block !important;
  min-height: 85px;
}
.gap-5 {
  gap: 1.25rem;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.duration-500 {
  transition-duration: 0.5s;
}
.transition-all {
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.shadow-md,
.shadow-sm {
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-solaniumDefault {
  border-radius: 20px;
}
.max-w-\[580px\] {
  max-width: 580px;
}
.bg-con {
  background-image: url("https://static.solanium.com/media/projects/header_front.png");
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
.max-w-\[580px\] {
  max-width: 580px;
}

.flex {
  display: flex;
}

.absolute {
  position: absolute;
}
.bg-\[\#2047F4\] {
  --tw-bg-opacity: 1;
  background-color: #311B92;
}
.border-\[\#ffffff\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-2 {
  border-width: 2px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.w-10 {
  width: 2.5rem;
}
.h-10 {
  height: 2.5rem;
}
.flex {
  display: flex;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.z-30 {
  z-index: 30;
}
.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}
.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.z-10 {
  z-index: 10;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-center {
  background-position: 50%;
}
.bg-cover {
  background-size: cover;
}
.rounded-t-solaniumDefault {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.h-\[180px\] {
  height: 180px;
}
.pt-5 {
  padding-top: 1.25rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.justify-between {
  justify-content: space-between;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}

.mb-4 {
  margin-bottom: 1rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.font-poppins-bold {
}
.text-\[\#6376AE\] {
  opacity: 0.7;
  color: white;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.leading-\[26px\] {
  line-height: 26px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.hide-para {
  display: none;
}

.pb-30px {
  padding-bottom: 44px;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.card-bg {
  background-color: rgba(35, 40, 47, 0.7) !important;
}
.no-decor {
  position: relative !important;
  text-decoration: none !important;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.gap-5 {
  gap: 1.25rem;
}

.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.gap-\[10px\] {
  gap: 10px;
}
.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}
.gap-2 {
  gap: 0.5rem;
}

.w-6 {
  width: 1.5rem;
}
.h-6 {
  height: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}
.rounded-br-solaniumDefault {
  border-bottom-right-radius: 20px;
}

.rounded-bl-solaniumDefault {
  border-bottom-left-radius: 20px;
}
.gap-1 {
  gap: 0.25rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.w-full {
  width: 100%;
}
.h-9 {
  height: 2.25rem;
}
.inline-flex {
  display: inline-flex;
}
.lop-mar{
    margin-top: 23px !important;
    margin-right: 23px !important;
}
.pex-5{
    
    padding-top: 1.25rem;
 
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.meb-4{
    margin-bottom: 1rem;
}
.gaep-5{
    gap: 1.25rem;
}
.bot-pad{
    padding-bottom: 1.25rem;
}
.projects-pool-header{
  text-align: left !important;
}
@media (min-width: 640px) {
    .meb-4{
    margin-bottom: 1.5rem;
}
  .sm\:pb-30px {
    padding-bottom: 30px;
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:text-\[40px\] {
    font-size: 40px;
  }
  .sm\:text-base {
    font-size: 1rem;
    -webkit-line-clamp: 1;
    line-height: 1.5rem;
  }
  .sm\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:line-clamp-3 {
    -webkit-line-clamp: 3;
  }
  .sm\:line-clamp-1,
  .sm\:line-clamp-3 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .sm\:mb-\[10px\] {
    margin-bottom: 10px;
  }
  .sm\:pt-30px {
    padding-top: 30px !important;
  }
  .sm\:px-30px {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
