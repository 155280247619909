.get-ready-badge.tile--with-shadow {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 32px;
}
.get-ready-badge .tile__main {
  font-size: 40px;
  line-height: 1.4;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width:750px) {
  .get-ready-section {
    padding-top: 54px !important;
    padding-bottom: 25px !important;
}
  
}
@media (max-width: 550px) {
  .get-ready-badge .tile__main {
    font-size: 32px !important;
  }
}
