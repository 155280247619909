@media (max-width:1024px) {
    .paragraphs{
        margin-left: 0 !important;
    }
}
@media (max-width:1690px) {
    .solaso{
        width: 100% !important;
    }
    
}